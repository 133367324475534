<div class="header" style="position: fixed" *ngIf="!isLogin">
  <div class="pull-left sm-table">
    <div class="header-inner">
      <div class="brand inline">
        <img
          routerLink="home"
          src="assets/img/lgt.png"
          alt="logo"
          data-src="assets/img/lgt.png"
          data-src-retina="assets/img/lgt.png"
          class="logoHeader"
        />
      </div>
      <ul class="notification-list no-margin b-grey b-l b-r no-style p-l-30 p-r-20">
        <li class="p-r-15 inline">
          <div class="dropdown" id="menuRol">
            <a
              id="notification-center"
              style="color: #626262; cursor: pointer"
              class="glyphicon glyphicon-cog"
              data-toggle="dropdown"
            >
            </a>
            <!--  START Notification Dropdown -->
            <div
              class="dropdown-menu notification-toggle"
              role="menu"
              aria-labelledby="notification-center"
            >
              <div class="notification-panel">
                <div
                  class="notification-body scrollable"
                  style="overflow-y: auto"
                  (click)="sliderFunctionality()"
                >
                  <div
                    class="notification-item unread clearfix"
                    *ngIf="hasPermission('ClientsRead')"
                  >
                    <div class="heading open">
                      <a
                        routerLink="clientlist"
                        class="minimenu-text pull-left"
                        aria-label="Clients"
                      >
                        <i class="fa fa-building-o fs-16 m-r-10"></i>
                        <span class="bold">Clients</span>
                      </a>
                    </div>
                  </div>
                  <div class="notification-item clearfix" *ngIf="hasPermission('ProductsRead')">
                    <div class="heading">
                      <a
                        routerLink="products"
                        class="minimenu-text pull-left"
                        aria-label="Products"
                      >
                        <i class="fa fa-product-hunt fs-16 m-r-10"></i>
                        <span class="bold">Products</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('ClientProductsRead')"
                  >
                    <div class="heading">
                      <a
                        routerLink="clientproductlist"
                        aria-label="Client Products"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-product-hunt fs-16 m-r-10"></i>
                        <span class="bold">Client Products</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('EquipmentCodesRead')"
                  >
                    <div class="heading">
                      <a
                        routerLink="equipment"
                        aria-label="Equipment Codes"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-product-hunt fs-16 m-r-10"></i>
                        <span class="bold">Equipment Codes</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('BusinessRulesRead')"
                  >
                    <div class="heading">
                      <a routerLink="rates" aria-label="Rates" class="minimenu-text pull-left">
                        <i class="fa fa-file-text-o fs-16 m-r-10"></i>
                        <span class="bold">Rates</span>
                      </a>
                    </div>
                  </div>
                  <div class="notification-item clearfix">
                    <div class="heading">
                      <a
                        routerLink="chargeTypes"
                        aria-label="Charge Types"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-square-o fs-16 m-r-10"></i>
                        <span class="bold">Charge Types</span>
                      </a>
                    </div>
                  </div>
                  <div class="notification-item clearfix" *ngIf="hasPermission('PeopleNetChange')">
                    <div class="heading">
                      <a
                        routerLink="peoplenetTest"
                        aria-label="Peoplenet"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-file-text-o fs-16 m-r-10"></i>
                        <span class="bold">Peoplenet testing tool</span>
                      </a>
                    </div>
                  </div>
                  <div class="notification-item clearfix" *ngIf="hasPermission('LocationsRead')">
                    <div class="heading">
                      <a
                        routerLink="locations"
                        aria-label="Locations"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">Locations</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekImportChange')"
                  >
                    <div class="heading">
                      <a routerLink="efs" aria-label="EFS Import" class="minimenu-text pull-left">
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">EFS Import</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekImportChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="efsBatchHistory"
                        aria-label="EFS Batch History"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">EFS Batch History</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekImportChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="efsAdmin"
                        aria-label="EFS Admin"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">EFS Imported Data</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekImportChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="efsChargeTypes"
                        aria-label="EFS Charge Types"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">EFS Charge Types</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekImportChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="tchek"
                        aria-label="Tchek Import"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">T-Chek import</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekExpensesChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="settlements/driverExpenses"
                        aria-label="Tchek Fuel Expenses"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">T-Chek Fuel Expenses</span>
                      </a>
                    </div>
                  </div>
                  <div
                    class="notification-item clearfix"
                    *ngIf="hasPermission('TChekExpressCodesChange')"
                  >
                    <div class="heading">
                      <a
                        routerLink="settlements/companyExpenses"
                        aria-label="Tchek Express Codes"
                        class="minimenu-text pull-left"
                      >
                        <i class="fa fa-wrench fs-16 m-r-10"></i>
                        <span class="bold">T-Chek Express Codes</span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="pull-right">
    <!-- START User Info-->
    <div class="visible-lg visible-md m-t-10">
      <div class="pull-left p-r-10 p-t-10 fs-16 font-heading">
        <span class="semi-bold">{{ username }}</span>
      </div>
      <div class="dropdown pull-right">
        <button
          class="profile-dropdown-toggle"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          <span class="thumbnail-wrapper d32 circular inline m-t-5">
            <img
              src="assets/img/lgt.png"
              alt=""
              data-src="assets/img/lgt.png"
              data-src-retina="assets/img/lgt.png"
              width="32"
              height="32"
            />
          </span>
        </button>
        <ul class="dropdown-menu profile-dropdown" role="menu">
          <li>
            <a> <i class="pg-settings_small"></i> Settings</a>
          </li>
          <li>
            <a> <i class="pg-outdent"></i> Feedback</a>
          </li>
          <li>
            <a> <i class="pg-signals"></i> Help</a>
          </li>
          <li class="bg-master-lighter">
            <a class="clearfix" (click)="logout()" style="cursor: pointer">
              <span class="pull-left">Logout</span>
              <span class="pull-right">
                <i class="pg-power"></i>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <!-- END User Info-->
  </div>
</div>
<!-- END HEADER -->
