import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService, SelectItem } from "primeng/api";
import { EfsService } from "@app/efs/efs.service";
import { EfsChargeTypeMapping } from "@app/shared/models/fuel/EfsChargeTypeMapping.model";
import { ChargeType } from "@app/shared/models/ChargeType.model";
import { ActivatedRoute } from "@angular/router";
import { SpinnerService } from "@app/shared/services/spinner/spinner.service";

@Component({
  selector: "app-edit-charge-type-mapping",
  templateUrl: "./edit-charge-type-mapping.component.html",
  styleUrl: "./edit-charge-type-mapping.component.css",
})
export class EditChargeTypeMappingComponent implements OnInit {
  @Input() mapping: EfsChargeTypeMapping = new EfsChargeTypeMapping();
  @Output() mappingChange = new EventEmitter();
  @Input() isEditing: boolean;
  @Output() addedEvent = new EventEmitter();

  chargeTypes: ChargeType[] = [];
  settlementColumns: SelectItem[] = [
    { label: "Fuel", value: "FUEL" },
    { label: "Other", value: "OTHER" },
    { label: "Cash", value: "CASH" },
    { label: "Fee", value: "FEE" },
  ];
  chargeToOptions: SelectItem[] = [
    { label: "Driver", value: "DRIVER" },
    { label: "Company", value: "COMPANY" },
  ];

  constructor(
    private efsService: EfsService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private spinner: SpinnerService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {
    this.chargeTypes = this.route.snapshot.data["chargeTypes"];
  }

  onSubmit() {
    if (this.isEditing) {
      this.editMapping();
    } else {
      this.create();
    }
  }

  create() {
    this.spinner.show();
    this.efsService.addChargeTypeMapping(this.mapping).subscribe({
      next: (created: any) => {
        this.addedEvent.emit(created);
        this.closeModal();
        this.spinner.hide();
      },
      error: (error) => {
        this.toastr.error("Error creating charge type. Make sure all fields are filled out.");
        console.dir(error);
        this.spinner.hide();
      },
    });
  }

  editMapping() {
    this.efsService.editChargeTypeMapping(this.mapping).subscribe({
      next: (edited: any) => {
        this.mappingChange.emit(edited);
        this.closeModal();
      },
      error: (error) => {
        this.toastr.error("Error editing charge type");
        console.dir(error);
      },
    });
  }

  confirmationDelete() {
    this.confirmationService.confirm({
      key: "deleteDialog",
      message: "Do you really want to delete this?",
      accept: () => {
        this.delete(this.mapping.id);
        this.closeModal();
      },
      reject: () => {},
    });
  }

  delete(id: string) {
    this.efsService.deleteChargeTypeMapping(id).subscribe({
      next: (result) => {
        this.mapping.dateDeletedUtc = new Date();
        this.mappingChange.emit(this.mapping);
      },
      error: (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
      },
    });
  }

  restore() {
    this.efsService.restoreChargeTypeMapping(this.mapping).subscribe({
      next: (result) => {
        this.mapping.dateDeletedUtc = null;
        this.mappingChange.emit(this.mapping);
        this.closeModal();
      },
      error: (error) => {
        this.toastr.warning(error, "Alert!");
        console.dir(error);
      },
    });
  }

  closeModal() {
    const closeModal = document.getElementsByClassName("p-ripple")[0] as HTMLElement;
    closeModal.click();
  }
}
