import { Component, OnInit } from "@angular/core";
import { EfsService } from "../efs.service";
import { ToastrService } from "ngx-toastr";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";

@Component({
  selector: "app-efs-import",
  templateUrl: "./efs-import.component.html",
  styleUrl: "./efs-import.component.css",
})
export class EfsImportComponent implements OnInit, CanComponentDeactivate {
  lastSyncDate: Date;
  syncing: boolean = false;
  importOpen: boolean = false;

  constructor(private efsService: EfsService, private toastrService: ToastrService) {}

  confirm() {
    return true;
  }

  ngOnInit() {
    this.efsService.getLastSyncDate().subscribe((data: any) => {
      this.lastSyncDate = data;
    });
  }

  sync() {
    if (this.syncing) return;
    this.syncing = true;

    this.efsService.sync().subscribe({
      next: () => {
        this.toastrService.success("EFS data synced successfully");
        this.lastSyncDate = new Date();
        this.syncing = false;
      },
      error: (error) => {
        this.toastrService.error("Error syncing EFS data");
        console.error(error);
        this.syncing = false;
      },
    });
  }

  import() {
    this.importOpen = true;
  }
}
