<div class="container">
  <div class="col-md-12">
    <legend>EFS Import</legend>

    <div style="max-width: 1000px">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        "
      >
        <div class="toggle-box">
          <p-checkbox
            [(ngModel)]="showDeleted"
            binary="true"
            inputId="binary"
            class="mr-2"
            (onChange)="filterMappings()"
          ></p-checkbox>
          <label for="binary">Show Deleted</label>
        </div>
        <button id="edit" name="edit" class="btn btn-primary" (click)="showAddForm()">
          Add EFS Category
        </button>
      </div>

      <p-table
        #mappingsTable
        [columns]="cols"
        [value]="filteredMappings"
        [autoLayout]="true"
        [loading]="loadingTable"
      >
        <ng-template pTemplate="caption">
          <div style="text-align: right">
            <i class="pi pi-search" style="margin: 4px 4px 0 0"></i>
            <input
              type="text"
              pInputText
              size="50"
              placeholder="Search..."
              (input)="mappingsTable.filterGlobal($event.target.value, 'contains')"
              style="width: auto"
            />
          </div>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th *ngFor="let col of cols" [pSortableColumn]="col.field">
              {{ col.header }}
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-mapping>
          <tr
            [ngClass]="{
              'clickable-row': !mapping.dateDeletedUtc,
              'deleted-row': mapping.dateDeletedUtc
            }"
            (dblclick)="showEditForm(mapping)"
          >
            <td>{{ mapping.categoryCode }}</td>
            <td>{{ mapping.description }}</td>
            <td>{{ mapping.chargeType?.name }}</td>
            <td>{{ mapping.settlementColumn }}</td>
            <td>{{ mapping.defaultChargeTo }}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</div>

<p-dialog
  header="Edit EFS Category"
  *ngIf="displayEdit"
  [(visible)]="displayEdit"
  [modal]="true"
  [style]="{ width: '350px' }"
  [minY]="100"
>
  <app-edit-charge-type-mapping
    [(mapping)]="selectedMapping"
    [isEditing]="true"
  ></app-edit-charge-type-mapping>
</p-dialog>

<p-dialog
  header="Add EFS Category"
  *ngIf="displayAdd"
  [(visible)]="displayAdd"
  [modal]="true"
  [style]="{ width: '300px' }"
  [minY]="70"
>
  <app-edit-charge-type-mapping
    [(mapping)]="selectedMapping"
    (addedEvent)="getMappings()"
    [isEditing]="false"
  ></app-edit-charge-type-mapping>
</p-dialog>
