import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { EfsService } from "@app/efs/efs.service";
import { LazyLoadEvent } from "primeng/api";
import { Table } from "primeng/table";
import { debounceTime, Subject, Subscription } from "rxjs";

@Component({
  selector: "app-imported-money-codes",
  templateUrl: "./imported-money-codes.component.html",
  styleUrl: "./imported-money-codes.component.css",
})
export class ImportedMoneyCodesComponent {
  @ViewChild("moneyCodesTable", { static: true }) table: Table;

  rows = 100;
  totalRecords = 0;
  records = [];
  isLoading = true;

  filtersSubject = new Subject();
  filtersSubscription: Subscription;
  filters = {
    // activeDate: "",
    // amount: "",
    // amountUsed: "",
    code: "",
    // contractId: "",
    // codeCreatedDate: "",
    // deductFees: "",
    // feeAmount: "",
    // feeType: "",
    // firstUseDate: "",
    // moneyCodeId: "",
    // issuedTo: "",
    // notes: "",
    // numUses: "",
    // payee: "",
    // status: "",
    // voided: "",
    // voidDate: "",
    // who: "",
    // unit: "",
    // name: "",
    // ponb: "",
  };

  columns = [
    { field: "activeDate", header: "Active", type: "date" },
    { field: "amount", header: "Amount", type: "string" },
    { field: "amountUsed", header: "Amount Used", type: "string" },
    { field: "code", header: "Code", type: "string" },
    { field: "contractId", header: "Contract Id", type: "string" },
    { field: "codeCreatedDate", header: "Code Created", type: "date" },
    { field: "deductFees", header: "Deduct Fees", type: "string" },
    { field: "feeAmount", header: "Fee Amount", type: "string" },
    { field: "feeType", header: "Fee Type", type: "string" },
    { field: "firstUseDate", header: "First Use", type: "date" },
    { field: "moneyCodeId", header: "Money Code", type: "string" },
    { field: "issuedTo", header: "Issued To", type: "string" },
    { field: "notes", header: "Notes", type: "string" },
    { field: "numUses", header: "Num Uses", type: "string" },
    { field: "payee", header: "Payee", type: "string" },
    { field: "status", header: "Status", type: "string" },
    { field: "voided", header: "Voided", type: "string" },
    { field: "voidDate", header: "Void Date", type: "date" },
    { field: "who", header: "Who", type: "string" },
    { field: "unit", header: "Unit", type: "string" },
    { field: "name", header: "Name", type: "string" },
    { field: "ponb", header: "PONB", type: "string" },
  ];

  constructor(private efsService: EfsService) {}

  ngOnInit() {
    this.filtersSubscription = this.filtersSubject.pipe(debounceTime(500)).subscribe(() => {
      this.table.first = 0;
      this.table.firstChange.emit(this.table.first);
      this.table.onLazyLoad.emit(this.table.createLazyLoadMetadata());
    });
  }

  ngOnDestroy() {
    this.filtersSubscription.unsubscribe();
  }

  onLazyLoad(event: LazyLoadEvent) {
    const observable = this.efsService.getImportedMoneyCodes(
      this.filters,
      event.rows,
      event.first,
      event.sortField,
      event.sortOrder
    );

    this.isLoading = true;
    observable.subscribe((result: any) => {
      this.records = result.records;
      this.totalRecords = result.totalRecords;
      this.isLoading = false;
    });
  }

  isFilterable(field: string) {
    return Object.keys(this.filters).includes(field);
  }
}
