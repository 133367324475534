<h3>Money Codes</h3>
<p-table
  #moneyCodesTable
  [columns]="columns"
  [value]="records"
  [totalRecords]="totalRecords"
  [rows]="rows"
  [paginator]="true"
  [loading]="isLoading"
  [scrollable]="true"
  scrollHeight="600px"
  [lazy]="true"
  (onLazyLoad)="onLazyLoad($event)"
>
  <ng-template pTemplate="header" let-columns>
    <tr>
      <th
        [pSortableColumn]="column.field"
        *ngFor="let column of columns"
        [pSortableColumn]="column.field"
        [style.width]="column.width"
      >
        {{ column.header }}
        <p-sortIcon [field]="column.field"></p-sortIcon>
      </th>
    </tr>
    <tr>
      <th *ngFor="let column of columns">
        <input
          *ngIf="isFilterable(column.field)"
          type="text"
          [(ngModel)]="filters[column.field]"
          (input)="filtersSubject.next($event)"
        />
      </th>
    </tr>
  </ng-template>

  <ng-template pTemplate="body" let-record>
    <tr>
      <td *ngFor="let column of columns">
        <span *ngIf="column.type == 'string'">{{ record[column.field] }}</span>
        <span *ngIf="column.type == 'date'">{{ record[column.field] | date : "short" }}</span>
      </td>
    </tr>
  </ng-template>
</p-table>
