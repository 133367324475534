<div class="container box">
  <div class="box-header">
    <div class="col-md-12">
      <legend>Settlements</legend>
    </div>
    <div class="row">
      <div style="display: flex; flex-direction: row; column-gap: 5px" class="col-sm-12">
        <div
          style="
            display: flex;
            flex-direction: row;
            align-items: center;
            column-gap: 5px;
            min-width: 230px;
          "
        >
          <input
            id="beginDate"
            name="beginDate"
            type="text"
            [inputMask]="dateInputMask"
            autocomplete="off"
            placeholder="mm/dd/yyyy"
            aria-label="beginDate"
            class="form-control input-md"
            style="text-align: left"
            [(ngModel)]="ranges.beginDate"
            (ngModelChange)="onDateRangeChanged($event, 'beginDate')"
          />
          <div>-</div>
          <input
            id="endDate"
            name="endDate"
            type="text"
            aria-label="endDate"
            [inputMask]="dateInputMask"
            autocomplete="off"
            placeholder="mm/dd/yyyy"
            style="text-align: left"
            class="form-control input-md"
            [(ngModel)]="ranges.endDate"
            (ngModelChange)="onDateRangeChanged($event, 'endDate')"
          />
        </div>
        <button
          type="button"
          class="btn btn-lg btn-nav"
          data-toggle="modal"
          data-target="#modalDrivers"
          (click)="getDriversByBatch()"
        >
          DRIVERS
        </button>
        <button
          type="button"
          class="btn btn-lg btn-nav"
          data-toggle="modal"
          data-target="#modalNewBatch"
          (click)="getUnassignedDrivers()"
        >
          CREATE NEW BATCH
        </button>
        <button
          type="button"
          class="btn btn-lg btn-nav"
          (click)="closeBatchForAll()"
          *ngIf="batch.status != null && batch.status == 'ACTIVE' && batchExist == true"
        >
          CLOSE BATCH {{ batch.batchNo }}
        </button>
        <button type="button" class="btn btn-lg btn-nav" routerLink="deductions">DEDUCTIONS</button>
        <button type="button" class="btn btn-lg btn-nav" (click)="generateExcel()">
          EXPORT TO EXCEL
        </button>
        <button
          *ngIf="batch?.status == 'ACTIVE'"
          type="button"
          class="btn btn-lg btn-nav"
          data-toggle="modal"
          [disabled]="batch?.importedDeductions"
          data-target="#modalImportDeductions"
          data-backdrop="static"
        >
          Import Deductions
        </button>
        <button
          *ngIf="batch?.status == 'ACTIVE'"
          type="button"
          class="btn btn-lg btn-nav"
          (click)="deleteDeductions()"
        >
          Delete Deductions
        </button>
        <button
          *ngIf="batch?.status == 'ACTIVE'"
          type="button"
          class="btn btn-lg btn-nav"
          (click)="deleteFuel()"
        >
          Delete Fuel
        </button>
        <button
          type="button"
          class="btn btn-lg btn-nav"
          (click)="releaseBatchForAll()"
          *ngIf="batch.status != null && batch.status == 'ACTIVE' && batchExist == true"
        >
          RELEASE BATCH {{ batch.batchNo }}
        </button>
      </div>
    </div>
    <div class="row" style="margin-top: 1rem">
      <div class="col-md-12" style="display: flex">
        <div *ngIf="ranges" style="margin-right: 1rem">
          <p-dropdown
            [options]="batchesList"
            [(ngModel)]="selectedBatch"
            [filter]="true"
            optionLabel="itemName"
            filterBy="itemName"
            (onChange)="batchSelected()"
            tabbableDropdown
          ></p-dropdown>
        </div>
        <p-multiSelect
          [options]="scrollableColumns"
          [(ngModel)]="selectedColumns"
          optionLabel="header"
          selectedItemsLabel="{0} columns selected"
          [style]="{ minWidth: '200px', marginBottom: '10px' }"
          placeholder="Choose Columns"
          tabbableMultiselect
        ></p-multiSelect>
      </div>
    </div>
    <div class="row" style="margin-bottom: 6px">
      <div class="col-md-12 d-flex">
        <button
          type="button"
          class="btn btn-default"
          (click)="saveBatchDataChange()"
          [disabled]="batchDataChanged.length == 0"
        >
          SAVE
        </button>
        <div>
          <button
            type="button"
            class="btn btn-secondary"
            style="margin-left: 1rem"
            [routerLink]="['/settlements/allloads', selectedBatch?.globalBatchId]"
          >
            ALL LOADS
          </button>
        </div>
        <div>
          <button
            *ngIf="isBatchSelected"
            type="button"
            class="btn btn-secondary"
            style="margin-left: 1rem"
            (click)="sendDriverSettlementEmails()"
          >
            SEND SETTLEMENT EMAILS
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="box-content">
    <p-table
      #dtSettlements
      [columns]="columnsToShow"
      [value]="tableData"
      stateStorage="session"
      stateKey="settlements-table-local"
      (onEditComplete)="editComplete($event)"
      (onEditCancel)="cancelChange($event)"
      [loading]="loading"
      id="tblSettlements"
      (onFilter)="onFilter($event)"
      sortMode="multiple"
      [scrollable]="true"
      scrollHeight="650px"
      [virtualScroll]="true"
      [virtualScrollItemSize]="42"
      [virtualScrollOptions]="{ appendOnly: true }"
      [rowTrackBy]="trackByFunction"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th style="width: 40px" pFrozenColumn [frozen]="true"></th>
          <th style="text-align: center" style="width: 50px" pFrozenColumn [frozen]="true">
            <p-checkbox (click)="toggleBatchesSelectAll()" binary="true"></p-checkbox>
          </th>
          <th style="width: 120px" pFrozenColumn [frozen]="true"></th>
          <th
            *ngFor="let col of columns"
            [ngSwitch]="col.type"
            style="text-align: center"
            [style.width]="col.width"
            pFrozenColumn
            [frozen]="col.frozenLeft"
          >
            <input
              pInputText
              type="text"
              (input)="$any(dtSettlements).filter($event.target.value, col.prop, 'contains')"
              [value]="$any(dtSettlements).filters[col.prop]?.value"
              [style.width]="col.searchWidth"
              [hidden]="col.isAction"
            />
          </th>
        </tr>
        <tr>
          <th pFrozenColumn [frozen]="true"></th>
          <th pFrozenColumn [frozen]="true"></th>
          <th style="text-align: center" pFrozenColumn [frozen]="true">Email Date</th>
          <th
            *ngFor="let col of columns"
            [pSortableColumn]="col.prop"
            style="height: 75px; text-align: center; overflow-wrap: break-word"
            pFrozenColumn
            [frozen]="col.frozenLeft"
          >
            <i *ngIf="col.isEditable" class="fa fa-pencil"></i>
            {{ col.name }}
          </th>
        </tr>
      </ng-template>
      <!-- Table body-->
      <ng-template pTemplate="body" let-rowData let-columns="columns" let-y="rowIndex">
        <tr>
          <td style="text-overflow: clip" pFrozenColumn [frozen]="true">
            <img
              title="Go To Driver Details"
              src="assets/img/details.svg"
              class="detailButton"
              (click)="driverSettlements(rowData.driverId)"
            />
          </td>
          <td style="text-align: center" pFrozenColumn [frozen]="true">
            <p-checkbox
              [(ngModel)]="rowData.isSelected"
              binary="true"
              (onChange)="checkBatchSelected()"
            ></p-checkbox>
          </td>
          <td pFrozenColumn [frozen]="true">
            <span *ngIf="rowData.emailError" style="color: red">{{ rowData.emailError }}</span>
            <span *ngIf="rowData.lastEmail && !rowData.emailError">
              {{ rowData.lastEmail | date : "MM/dd/yyyy" }}
            </span>
          </td>
          <ng-container *ngFor="let col of columns; index as x">
            <td
              *ngIf="col.isEditable"
              [pEditableColumn]="rowData"
              [pEditableColumnField]="col"
              id="celda{{ x }}{{ y }}{{ col.frozenLeft ? 'frozen' : 'scrollable' }}"
              (click)="setSelectedCellFromClick($event)"
              pFrozenColumn
              [frozen]="col.frozenLeft"
            >
              <div
                [style.color]="rowData[col.prop] < 0 ? 'RED' : null"
                [style.textAlign]="!rowData[col.prop] ? 'center' : 'right'"
                class="editable-cell"
              >
                <p-cellEditor class="elements-table">
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      type="number"
                      [(ngModel)]="rowData[col.prop]"
                      [disabled]="batch.status == 'CLOSED' || confirmationModalOpen"
                      (change)="deductionChange(rowData[col.prop], col, rowData.driverId)"
                      (keydown)="onKeyBoard($event, x, y)"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ !rowData[col.prop] ? "-" : (rowData[col.prop] | currency) }}
                  </ng-template>
                </p-cellEditor>
              </div>
            </td>
            <td
              *ngIf="!col.isEditable"
              id="celda{{ x }}{{ y }}{{ col.frozenLeft ? 'frozen' : 'scrollable' }}"
              (click)="setSelectedCellFromClick($event)"
              pFrozenColumn
              [frozen]="col.frozenLeft"
            >
              <div
                [style.color]="
                  col.prop == 'earn' && rowData['earn'] != rowData['earnProjected'] ? 'RED' : null
                "
              >
                <div
                  *ngIf="col.temp == 'MONEY'"
                  [style.color]="rowData[col.prop] < 0 ? 'RED' : null"
                  style="text-align: right"
                >
                  {{ !rowData[col.prop] ? "$0" : (rowData[col.prop] | currency) }}
                </div>
                <div
                  *ngIf="col.temp == 'TEXT'"
                  style="text-align: left"
                  [title]="rowData[col.prop]?.toUpperCase()"
                >
                  {{ rowData[col.prop] }}
                </div>
                <div *ngIf="col.temp == 'NUMBER'" style="text-align: right">
                  {{ rowData[col.prop] }}
                </div>
              </div>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <!-- Table footer -->
      <ng-template pTemplate="footer" let-columns>
        <tr>
          <td pFrozenColumn [frozen]="true"></td>
          <td pFrozenColumn [frozen]="true"></td>
          <td pFrozenColumn [frozen]="true"></td>
          <td *ngFor="let col of columns" style="text-align: right; height: 34px" pFrozenColumn [frozen]="col.frozenLeft">
            <b [style.color]="totals[col.prop] < 0 ? 'RED' : null">
              {{
                totals[col.prop] == 0
                  ? "-"
                  : totals[col.prop] == "N"
                  ? ""
                  : (totals[col.prop] | currency)
              }}
            </b>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="emptymessage" let-columns>
        <tr>
          <td [attr.colspan]="columns.length" style="height: 34px; text-align: right">
            No records found
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>

<!-- give the confirm dialog a max width -->
<p-confirmDialog
  #cd
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
  [style]="{ 'max-width': '600px' }"
>
  <p-footer>
    <button
      style="margin-right: 10px"
      type="button"
      pButton
      icon="pi pi-times"
      label="No"
      class="btn-danger"
      (click)="cd.reject()"
    ></button>
    <button
      type="button"
      pButton
      icon="pi pi-check"
      label="Yes"
      class="btn-success"
      (click)="cd.accept()"
    ></button>
  </p-footer>
</p-confirmDialog>

<div class="modal fade" id="modalNewBatch" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          id="closeNewBatchModal"
          class="close"
          data-dismiss="modal"
          (click)="newBatchModalCancelled()"
        >
          &times;
        </button>
        <h4 class="modal-title">CREATE NEW BATCH</h4>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-md">
              <label>Start Date</label>
              <input
                id="newBatchModalDataStartDate"
                name="newBatchModalDataStartDate"
                type="text"
                [inputMask]="dateInputMask"
                autocomplete="off"
                class="form-control input-md"
                placeholder="mm/dd/yyyy"
                style="text-align: left"
                aria-label="newBatchModalDataStartDate"
                [(ngModel)]="newBatchModalData.startDate"
              />
              <label>End Date</label>
              <input
                id="newBatchModalDataEndDate"
                name="newBatchModalDataEndDate"
                class="form-control input-md"
                type="text"
                aria-label="newBatchModalDataEndDate"
                [inputMask]="dateInputMask"
                autocomplete="off"
                placeholder="mm/dd/yyyy"
                style="text-align: left"
                [(ngModel)]="newBatchModalData.endDate"
              />
            </div>
          </div>
          <br />
          <div class="row" id="table-row">
            <p-table
              #tt
              id="tt"
              [columns]="newBatchColumns"
              [value]="newBatchModalData.unassignedDrivers"
              [scrollable]="true"
              scrollHeight="400px"
            >
              <ng-template pTemplate="colgroup" let-columns>
                <colgroup>
                  <col *ngFor="let col of columns" [style.width]="col.width" />
                </colgroup>
              </ng-template>
              <ng-template pTemplate="header" let-columns>
                <!-- Table filter-->
                <tr>
                  <th>
                    <input
                      style="width: 100%"
                      type="text"
                      id="createBatchDriverFilter"
                      (input)="$any(tt).filter($event.target.value, 'driverName', 'contains')"
                      [value]="$any(tt).filters['driverName']?.value"
                    />
                  </th>
                  <th>
                    <input
                      style="width: 100%"
                      type="text"
                      (input)="$any(tt).filter($event.target.value, 'type', 'contains')"
                      [value]="$any(tt).filters['type']?.value"
                    />
                  </th>
                  <th></th>
                </tr>
                <!-- Table headers-->
                <tr>
                  <th [pSortableColumn]="'driverName'" style="text-align: center">Driver</th>
                  <th [pSortableColumn]="'type'" style="text-align: center">Type</th>
                  <th style="text-align: center">
                    <p-checkbox
                      name="selectAllDriversNewBatch"
                      [(ngModel)]="selectAllDriversNewBatchToggle"
                      binary="true"
                      (onChange)="selectAllDriversInNewBatchModalChanged($event.checked)"
                    >
                    </p-checkbox>
                  </th>
                </tr>
              </ng-template>
              <!-- Table body-->
              <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr>
                  <td *ngFor="let col of columns">
                    <div *ngIf="!col.isAction">
                      <div *ngIf="col.temp == 'TEXT'">
                        {{ rowData[col.prop] }}
                      </div>
                    </div>
                    <div *ngIf="col.isAction" style="text-align: center">
                      <p-checkbox
                        [(ngModel)]="rowData[col.prop]"
                        binary="true"
                        name="switchActive"
                        (onChange)="driverToIncludeInNewBatchChanged()"
                      >
                      </p-checkbox>
                    </div>
                  </td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default"
          data-dismiss="modal"
          (click)="newBatchModalCancelled()"
        >
          Close
        </button>
        <button type="button" class="btn btn-default btnSave" (click)="createNewBatch()">
          Create new batch
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="modalDrivers" role="dialog">
  <div class="modal-dialog">
    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          id="closeNewBatchModal"
          class="close"
          data-dismiss="modal"
          (click)="newBatchModalCancelled()"
        >
          &times;
        </button>
        <h4 class="modal-title">DRIVERS ON BATCH NO {{ batch.batchNo }}</h4>
      </div>
      <div class="modal-body">
        <div class="container">
          <div class="row">
            <div class="col-md">
              <p-table
                #td
                [columns]="driverBatchColumns"
                [value]="batchDrivers"
                [scrollable]="true"
                scrollHeight="400px"
              >
                <ng-template pTemplate="colgroup" let-columns>
                  <colgroup>
                    <col *ngFor="let col of columns" [style.width]="col.width" />
                  </colgroup>
                </ng-template>
                <ng-template pTemplate="header" let-columns>
                  <!-- Table filter-->
                  <tr>
                    <th>
                      <input
                        style="width: 100%"
                        type="text"
                        (input)="$any(td).filter($event.target.value, 'driverName', 'contains')"
                        [value]="$any(td).filters['driverName']?.value"
                      />
                    </th>
                    <th>
                      <input
                        style="width: 100%"
                        type="text"
                        (input)="$any(td).filter($event.target.value, 'batchNo', 'contains')"
                        [value]="$any(td).filters['batchNo']?.value"
                      />
                    </th>
                    <th></th>
                  </tr>
                  <!-- Table headers-->
                  <tr>
                    <th style="text-align: center">Driver</th>
                    <th style="text-align: center">Actual Batch</th>
                    <th style="text-align: left">
                      <p-checkbox
                        name="selectAllDriversSettlement"
                        [(ngModel)]="selectAllDriversInDriversBatchModal"
                        [disabled]="batch.status != 'ACTIVE'"
                        binary="true"
                        (onChange)="selectAllDriversInDriversBatchModalChanged($event.checked)"
                      >
                      </p-checkbox>
                    </th>
                  </tr>
                </ng-template>
                <!-- Table body-->
                <ng-template pTemplate="body" let-rowData let-columns="columns">
                  <tr>
                    <td>
                      <div>
                        {{ rowData.driverName }}
                      </div>
                    </td>
                    <td>
                      <div>
                        {{ rowData.batchNo }}
                      </div>
                    </td>
                    <td>
                      <p-checkbox
                        [(ngModel)]="rowData.newDriverBatch"
                        binary="true"
                        name="switchActive"
                        [disabled]="batch.status != 'ACTIVE'"
                        (onChange)="driverToIncludeChanged()"
                      >
                      </p-checkbox>
                    </td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-default btnSave"
          (click)="saveDriverBatch()"
          *ngIf="batch.status === 'ACTIVE'"
        >
          Save
        </button>
        <button type="button" class="btn btn-default" data-dismiss="modal" id="closeBatchModal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<app-import-deductions
  [batch]="batch"
  (onImported)="batchInfo(); batch.importedDeductions = true"
></app-import-deductions>

<p-dialog
  header="Send Batch Emails"
  *ngIf="displaySendBatchEmails"
  [(visible)]="displaySendBatchEmails"
  [modal]="true"
  [style]="{ width: '350px' }"
  [minY]="100"
>
  <app-send-batch-emails (refreshGrid)="batchEmailsSent()" [(model)]="driversToEmail">
  </app-send-batch-emails>
</p-dialog>

<p-dialog
  id="backgroundEmails"
  header="Batch Emails"
  *ngIf="displayBackgroundBatchEmails"
  [(visible)]="displayBackgroundBatchEmails"
  [modal]="true"
  [style]="{ width: '350px' }"
  [minY]="100"
>
  <div class="form-group">
    <label
      >Settlement emails will be sent in the background, you will be notified via email when all
      emails have finished sending.</label
    >
  </div>
  <p-footer>
    <div class="footer">
      <button type="button" class="btn btn-secondary mr-2" (click)="closeBackgroundEmailModal()">
        Okay
      </button>
    </div>
  </p-footer>
</p-dialog>
