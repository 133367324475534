<p-dialog
  header="Download from EFS"
  [(visible)]="visible"
  [modal]="true"
  [style]="{ width: '500px', minWidth: '300px' }"
>
  <label for="date">EFS Transactions end date</label> <br />
  <input
    type="text"
    [inputMask]="dateInputMask"
    autocomplete="off"
    placeholder="mm/dd/yyyy"
    [(ngModel)]="date"
    [attr.aria-label]="date"
    class="form-control"
    id="date"
  />

  <p-footer>
    <button type="button" class="btn btn-secondary mr-3" (click)="closeModal()">Close</button>
    <button type="button" class="btn btn-primary" (click)="import()">Import</button>
  </p-footer>
</p-dialog>
