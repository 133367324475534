import { throwError as observableThrowError } from "rxjs";

import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";

import { HttpClient } from "../../shared/services/http/http-client.service";
import { SpinnerService } from "@app/shared/services/spinner/spinner.service";
import { LocationViewModel } from "@app/shared/models/locations/LocationViewModel.model";
declare var ALKMaps: any;

@Injectable()
export class PcMilerService {
  currentSettup: any;
  constructor(private _http: HttpClient, private spinner: SpinnerService) {}

  findLastVersion() {
    return this._http.get("/api/PcMilerConfig/findLastVersion").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  listVersions() {
    return this._http.get("/api/PcMilerConfig/listVersions").pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  update(newData: any) {
    return this._http.patch("/api/PcMilerConfig/update", newData).pipe(
      map(async (res) => {
        await this.getCurrentSetup(true);
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  getCurrentSetup(forceFetch = false) {
    return new Promise((resolve, reject) => {
      if (!forceFetch && this.currentSettup) {
        resolve(this.currentSettup);
      } else {
        return this.findLastVersion()
          .toPromise()
          .then((currentSettings) => {
            return this.listVersions()
              .toPromise()
              .then((versions) => {
                this.currentSettup = { currentSettings, versions };
                resolve(this.currentSettup);
              });
          });
      }
    });
  }

  calculateDistanceBetweenStops(stop1: LocationViewModel, stop2: LocationViewModel) {
    const stopsString = `${stop1.longitude},${stop1.latitude};${stop2.longitude},${stop2.latitude}`;
    return this.generateRoute(stopsString).pipe(
      map((res) => {
        const report = res[0].ReportLines;
        const miles = Number(report[1].LMiles);
        return miles;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }

  geoCodeLocation(locat) {
    const location = {
      addr: locat.address,
      city: locat.city,
      state: locat.state,
      zip: locat.zipcode,
      postcodefilter: undefined,
      region: "NA",
    };
    if (locat.country == "MX") {
      location.postcodefilter = "Mexico";
    }
    this.spinner.show();
    return this.getCurrentSetup().then((currentSettup: any) => {
      return new Promise((resolve, reject) => {
        ALKMaps.APIKey = currentSettup.currentSettings.apiKey;
        try {
          ALKMaps.Geocoder.geocode({
            address: location,
            listSize: 5,
            success: (nearLocations) => {
              this.spinner.hide();
              resolve(nearLocations);
            },
            failure: (error) => {
              this.spinner.hide();
              error.location = locat;
              reject(error);
            },
          });
        } catch (error) {
          this.spinner.hide();
          reject(error);
        }
      });
    });
  }

  generateRoute(stopsString: string) {
    const endpoint = "https://pcmiler.alk.com/apis/rest/v1.0/Service.svc/route/routeReports";
    const settings = this.currentSettup.currentSettings;
    const apiKey = settings.apiKey;
    const options = {
      stops: stopsString,
      vehType: settings.vehicleType,
      routeType: settings.routingType,
      hwyOnly: settings.highwayOnly,
      overrideClass: settings.classOverrides,
      tollRoads: settings.tollRoads,
      openBorders: settings.bordersOpen,
      restrOverrides: settings.overrideRestrict,
      hazMat: settings.hazMatType,
      routeOpt: settings.routeOptimization,
      vehHeight: settings.trkHeight,
      vehLength: settings.trkLength,
      vehWidth: settings.trkWidth,
      vehWeight: settings.trkWeight,
      axles: settings.trkAxles,
      dataset: "Current",
      ferryDiscourage: settings.ferryDiscourage,
      sideOfStreetAdherence: settings.sideOfStreetAdherence,
      authToken: apiKey,
      reports: "Mileage",
    };

    const endpointUrl = `${endpoint}?${Object.keys(options)
      .map((key) => key + "=" + options[key])
      .join("&")}`;

    return this._http.get(endpointUrl).pipe(
      map((res) => {
        return res;
      }),
      catchError((error: any) => observableThrowError(error.message || "Server error"))
    );
  }
}
