<div class="container">
  <div class="col-md-12">
    <legend>EFS Import</legend>

    <div style="display: flex; align-items: center">
      <a class="btn btnUpload" (click)="sync()">
        <span>Sync with EFS</span>
        <p-progressSpinner
          *ngIf="syncing"
          [style]="{ width: '16px', height: '16px', marginLeft: '10px' }"
        ></p-progressSpinner>
      </a>
      <div style="margin-left: 10px">Last synced {{ lastSyncDate | date : "medium" }}</div>
    </div>
    <div style="margin-top: 10px">
      <a class="btn btnUpload" (click)="import()">
        <span>Import Fuel & Express</span>
      </a>
    </div>
  </div>

  <app-efs-import-modal [(visible)]="importOpen"></app-efs-import-modal>
</div>
