import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  HostListener,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SettlementsService } from "../shared/settlements.service";
import { MaskService } from "../../shared/services/mask/mask.service";
import { DataSharingService } from "../../shared/services/data/dataSharing.service";
import { DatePipe } from "@angular/common";
import { ToastrService } from "ngx-toastr";
import { ConfirmationService } from "primeng/api";
import { Table } from "primeng/table";
import { TcheckService } from "../../tcheck/tcheck.service";
import { CanComponentDeactivate } from "@app/shared/guards/confirmation/confirmation.guard";

@Component({
  selector: "app-settlementsHome",
  templateUrl: "./settlementsHome.component.html",
  styleUrls: ["./settlementsHome.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SettlementsHomeComponent implements OnInit, CanComponentDeactivate {
  @ViewChild("dtSettlements", { static: true }) td: Table;
  cellSelected: string;

  @HostListener("document:click", ["$event"]) onClickOut(event) {
    if (!this.td.el.nativeElement.contains(event.target)) {
      let editedCell = this.td.editingCell;
      if (typeof editedCell !== "undefined" && editedCell != null) {
        editedCell.classList.remove("p-cell-editing");
      }
    }
  }

  /**------ General --------*/
  errorMsg: any;
  batchExist: any = false;
  confirmationModalOpen: boolean = false;
  /**-----------------------*/

  /**------ Table ---------*/
  ranges: any;
  tableData: any = [];
  originalData: any = [];
  columns: any = [];
  frozenColumns: any[];
  scrollableColumns: any[];
  batchesSelectAll: boolean = false;
  isBatchSelected: boolean = false;

  columnsToShow: any[];
  _selectedColumns: any[];
  get selectedColumns(): any[] {
    return this._selectedColumns;
  }
  set selectedColumns(val: any[]) {
    //restore original order
    this._selectedColumns = this.scrollableColumns.filter((col) => val.includes(col));
    this.columnsToShow = this.frozenColumns.concat(this._selectedColumns);
  }

  totals: any = {};
  /**-----------------------*/

  /**------ Batches ---------*/
  batch: any = {};
  batchesList: any = [];
  batchDrivers: any = [];
  selectedBatch: any = {};
  batchesListSettings: any = {};
  batchDataChanged: any = [];
  /**-----------------------*/

  /**------ Modals ---------*/
  displayBackgroundBatchEmails: any = false;
  chargesChanged: any = [];
  selectedTypes: any = [];
  lastSelectedTypes: any = [];
  lastSelectedTypesInPermanentModal: any[];
  selectedTypesInPermanentModal: any = [];
  newBatchModalData: any = {
    startDate: "",
    endDate: "",
    unassignedDrivers: [],
  };
  newBatchColumns: any = [];
  driverBatchColumns: any = [];
  filters: any = {
    driver: [],
  };
  filterElementsBatchTable: any = [];
  filterElementsEditGlobal: any = [];
  filterElementsEditPermanent: any = [];
  filterElementsNewBatch: any = [];
  filterElementsCurrentBatch: any = [];
  columnsEditGlobalModal = [
    { field: "driverName", header: "Driver" },
    { field: "sectionType", header: "Type" },
    { field: "description", header: "Description" },
    { field: "newCustomValue", header: "Amount" },
  ];
  columnsNewBatchModal = [
    { field: "driverName", header: "Driver" },
    { field: "type", header: "Type" },
  ];
  columnsCurrentBatchModal = [
    { field: "driverName", header: "Driver" },
    { field: "batchNo", header: "Actual Batch" },
  ];
  displaySendBatchEmails: any = false;
  decimalLimitMask = this.maskService.getDecimalLimitMask(3);
  globalChanges: any = {};
  permanentChanges: any = {};
  selectAllDriversInDriversBatchModal: boolean;
  selectAllDriversNewBatchToggle: boolean;
  loading: boolean = true;
  dateInputMask: any;
  driversToEmail: any;

  constructor(
    private _SettlementsService: SettlementsService,
    private _DataSharingService: DataSharingService,
    private _ActivatedRoute: ActivatedRoute,
    private toastr: ToastrService,
    private maskService: MaskService,
    private _DatePipe: DatePipe,
    private router: Router,
    private _NgZone: NgZone,
    private _ConfirmationService: ConfirmationService,
    private tcheckService: TcheckService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    let past = new Date();
    past.setFullYear(past.getFullYear() - 2);
    let future = new Date();
    future.setFullYear(future.getFullYear() + 2);

    this.ranges = {
      beginDate: past,
      endDate: future,
    };
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
    this.selectAllDriversNewBatchToggle = false;
    this.selectAllDriversInDriversBatchModal = true;
    this.columns = this._ActivatedRoute.snapshot.data.columns;
    this.frozenColumns = [];
    this.scrollableColumns = [];
    let frozen = 0;
    for (const col of this.columns) {
      if (col.frozenLeft == true) {
        this.frozenColumns.push(col);
        frozen += col.widthSize;
      } else {
        col.header = col.name;
        this.scrollableColumns.push(col);
      }
    }
    this._selectedColumns = this.scrollableColumns;
    this.columnsToShow = this.frozenColumns.concat(this._selectedColumns);

    this.batchesListSettings = {
      text: "Select Batch",
      enableSearchFilter: true,
      singleSelection: true,
    };

    this.getBatchesList();

    this.newBatchColumns = [
      {
        name: "Driver",
        prop: "driverName",
        width: "160px",
        widthSize: 160,
        searchWidth: "90%",
        isAction: false,
        temp: "TEXT",
      },
      {
        name: "Type",
        prop: "type",
        searchWidth: "90%",
        width: "100px",
        widthSize: 100,
        isAction: false,
        temp: "TEXT",
      },
      {
        name: "",
        prop: "batchInclude",
        width: "40px",
        widthSize: 40,
        searchWidth: "90%",
        isAction: true,
      },
    ];

    this.driverBatchColumns = [
      {
        name: "Driver",
        width: "160px",
        prop: "driverName",
      },
      {
        name: "Actual Batch",
        prop: "batchNo",
        width: "100px",
      },
      { name: "", width: "40px", prop: "newDriverBatch" },
    ];
  }

  /** --------------- HOME TABLE MANAGE --------------------*/
  getBatchesList() {
    const { beginDate, endDate } = this.ranges;
    const parsedBeginDate =
      beginDate.getFullYear() + "-" + (beginDate.getMonth() + 1) + "-" + beginDate.getDate() + "";
    const parsedEndDate =
      endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + endDate.getDate() + "";

    this._SettlementsService.getGlobalBatchList(parsedBeginDate, parsedEndDate).subscribe(
      (resGlobalBatches: any) => {
        if (resGlobalBatches.length > 0) {
          this.batchExist = true;
          this.batchesList = resGlobalBatches.map((batch: any) => ({
            ...batch,
            globalBatchId: batch.id,
            id: batch.batchNo,
            itemName:
              "Batch No: " +
              batch.batchNo +
              " " +
              this._DatePipe.transform(batch.startDate, "MM/dd/yyyy", "GMT-00:00") +
              " - " +
              (batch.endDate != null
                ? this._DatePipe.transform(batch.endDate, "MM/dd/yyyy", "GMT-00:00") + " "
                : " ") +
              batch.status,
          }));
          if (sessionStorage.getItem("selectedBatch")) {
            const selectedBatchId = sessionStorage.getItem("selectedBatch");
            const selectedBatch = this.batchesList.find((b) => b.globalBatchId == selectedBatchId);
            this.selectedBatch = selectedBatch || this.batchesList[0];
            this.batch = selectedBatch || this.batchesList[0];
          } else {
            this.selectedBatch = this.batchesList[0];
            this.batch = this.batchesList[0];
          }
        } else {
          this.batchExist = false;
          this.batchesList = [];
          this.selectedBatch = null;
          this.batch = {};
        }

        this.tableData = [];
        this.originalData = [];
        this.totals = {};
        this.batchInfo();
        this.cdr.markForCheck();
      },
      (resError) => {
        this.errorMsg = resError;
      }
    );
  }

  onDateRangeChanged(event, prop) {
    this.ranges[prop] = event;
    if (!isNaN(event)) this.getBatchesList();
  }

  batchInfo() {
    if (this.batch.batchNo) {
      this._SettlementsService.getHomeData(this.batch.batchNo).subscribe(
        (data) => {
          this.tableData = data;
          for (const row of this.tableData) {
            row.isSelected = false;
          }
          this.originalData = JSON.parse(JSON.stringify(data));
          this.columns.map((header) => {
            this.totals[header.prop] = this.calculateTotals(header.prop);
          });
          this.batchDataChanged = [];
          this.loading = false;
          this.cdr.markForCheck();
        },
        (resHomeError) => {
          this.toastr.error("Error loading settlement data");
          this.errorMsg = resHomeError;
        }
      );
    }
  }

  batchSelected() {
    this.batch = this.selectedBatch;
    const batchId = this.selectedBatch.globalBatchId;
    sessionStorage.setItem("selectedBatch", batchId);
    this.batchInfo();
  }

  calculateTotals(prop, data = this.tableData) {
    if (prop == "driverName" || prop == "unitNumber" || prop == "") {
      return "N";
    }
    let total = 0;
    for (const row of data) {
      if (!isNaN(Number(row[prop]))) {
        total += Number(row[prop]);
      }
    }
    if (!isNaN(Number(total))) {
      return total.toFixed(2);
    }
    return total;
  }

  onFilter(event) {
    const { filteredValue } = event;
    for (const col of this.columns) {
      this.totals[col.prop] = this.calculateTotals(col.prop, filteredValue);
    }
  }

  trackByFunction(index, item) {
    return index;
  }

  /** --------------- END HOME TABLE MANAGE -----------------*/

  /** --------------- GENERAL FUNCTIONS --- -----------------*/

  filterList(list, filterList) {
    return list.filter(function (item) {
      for (const key of Object.keys(filterList)) {
        if (filterList[key] && filterList[key].length > 0) {
          let exist = false;
          filterList[key].forEach((element) => {
            if (item[key] == null) {
              item[key] = "";
            }
            if (item[key] === element.value) {
              exist = true;
            }
          });
          if (!exist) {
            return exist;
          }
        }
      }
      return true;
    });
  }

  /** ------------------ END GENERAL FUNCTIONS ------------------*/

  /** --------- MODAL EDIT GLOBAL (BATCH MODIFICATIONS) ---------*/

  viewLoads() {
    let beginDate =
      this.ranges.beginDate.year +
      "-" +
      ((this.ranges.beginDate.month <= 9 ? "0" : "") + this.ranges.beginDate.month) +
      "-" +
      ((this.ranges.beginDate.day <= 9 ? "0" : "") + this.ranges.beginDate.day) +
      "";
    let endDate =
      this.ranges.endDate.year +
      "-" +
      ((this.ranges.endDate.month <= 9 ? "0" : "") + this.ranges.endDate.month) +
      "-" +
      ((this.ranges.endDate.day <= 9 ? "0" : "") + this.ranges.endDate.day) +
      "";
    this.router.navigate(["/settlements/allloads/", this.selectedBatch.id], {
      queryParams: { beginDate: beginDate, endDate: endDate },
    });
  }

  trackChangeInGlobal(charge) {
    const isChargeAlreadyChanged =
      this.globalChanges[charge.id] != null && typeof this.globalChanges[charge.id] !== "undefined";
    if (isChargeAlreadyChanged) {
      const isNewValueDifferent =
        Number(this.globalChanges[charge.id].customValue) != Number(charge.newCustomValue);
      if (isNewValueDifferent) {
        this.globalChanges[charge.id] = charge;
      } else {
        delete this.globalChanges[charge.id];
      }
    } else {
      this.globalChanges[charge.id] = charge;
    }
  }

  noChagesMadeInGlobal() {
    if (Object.keys(this.globalChanges).length === 0 && this.globalChanges.constructor === Object) {
      return true;
    } else {
      return false;
    }
  }

  trackChangeInPermanent(charge) {
    const isChargeAlreadyChanged =
      this.permanentChanges[charge.id] != null &&
      typeof this.permanentChanges[charge.id] !== "undefined";
    if (isChargeAlreadyChanged) {
      const isNewValueDifferent =
        Number(this.permanentChanges[charge.id].customValue) != Number(charge.newCustomValue);
      if (isNewValueDifferent) {
        this.permanentChanges[charge.id] = charge;
      } else {
        delete this.permanentChanges[charge.id];
      }
    } else {
      this.permanentChanges[charge.id] = charge;
    }
  }

  noChagesMadeInPermanent() {
    if (
      Object.keys(this.permanentChanges).length === 0 &&
      this.permanentChanges.constructor === Object
    ) {
      return true;
    } else {
      return false;
    }
  }

  /** ------ END MODAL EDIT GLOBAL (BATCH MODIFICATIONS) ---------*/

  /** --------------- BATCH MANAGE ---------------------*/

  calculateTemporalTotals(driverId: number) {
    const originalData = this.originalData.find((data) => data.driverId === driverId);
    let driverData = this.tableData.find((data) => data.driverId === driverId);
    driverData.deductions = 0;
    for (const col of this.columns) {
      if (col.type == "DEDUCTION") {
        driverData.deductions -= driverData[col.prop] ?? 0;
      } else if (col.type == "DEFICIT") {
        driverData.deductions += driverData[col.prop] ?? 0;
      } else if (col.type == "ESCROW") {
        driverData.deductions -= driverData[col.prop] ?? 0;
      }
    }

    driverData.escrowBatch =
      (driverData.escrowWithdrawValue ?? 0) +
      (driverData.escrowDepositValue ?? 0) +
      (driverData.escrowInterestValue ?? 0);
    driverData.deficitBatch =
      (driverData.deficitWithdrawValue ?? 0) + (driverData.deficitDepositValue ?? 0);

    driverData.total =
      originalData.earn +
      originalData.reimbursement -
      originalData.fuel -
      originalData.nonFuel +
      driverData.deductions;
    driverData.escrowTotalNew = (originalData.escrowTotal ?? 0) + driverData.escrowBatch;
    driverData.deficitTotalNew = (originalData.deficitTotal ?? 0) + driverData.deficitBatch;
    this.columns.map((header) => {
      this.totals[header.prop] = this.calculateTotals(header.prop);
    });
  }

  cancelChange(event: any) {
    // On Edit Cancel
    const driverId = event.data.driverId;
    this.calculateTemporalTotals(driverId);
  }

  editComplete(event: any) {
    // On Edit Complete
    if (!event || !event.data) return;
    const col = event.field;
    // if it was a withdraw value, force it to be negative
    if (col.prop.includes("WithdrawValue")) {
      event.data[col.prop] = -Math.abs(event.data[col.prop]);
    } else if (col.prop.includes("DepositValue")) {
      event.data[col.prop] = Math.abs(event.data[col.prop]);
    }
    const value = event.data[col.prop];

    const driverId = event.data.driverId;
    this.deductionChange(value, col, driverId);
  }

  deductionChange(value, col, driverId) {
    if (this.confirmationModalOpen) return;
    const deductionId = col.type == "DEDUCTION" ? col.prop : null;

    // remove from batchDataChanged if it exists, this way we don't have duplicates
    const index = this.batchDataChanged.findIndex(
      (data) => data.driverId === driverId && data.prop === col.prop
    );
    if (index > -1) {
      this.batchDataChanged.splice(index, 1);
    }

    const originalData = this.originalData.find((data) => data.driverId === driverId);
    const originalValue = originalData[col.prop];
    const batchId = originalData.id;

    this.calculateTemporalTotals(driverId);

    // if value is the same as original, don't add to batchDataChanged
    if (originalValue === value) return;

    const batchChange = {
      deductionId,
      driverId,
      prop: col.prop,
      value,
      originalValue,
      batchId: batchId,
    };

    this.batchDataChanged.push(batchChange);
  }

  onKeyBoard(event, x, y) {
    var keyPress = event.key;
    var evt = new Event("click");
    if (keyPress === "Enter") {
      const cellId = "celda" + String(x) + String(y + 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        setTimeout(function () {
          button.dispatchEvent(evt);
        }, 200);
      }
    }
    if (keyPress === "ArrowRight") {
      const cellId = "celda" + String(x + 1) + String(y) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      }
    }
    if (keyPress === "ArrowLeft") {
      const cellId = "celda" + String(x - 1) + String(y) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      }
    }
    if (keyPress === "ArrowUp") {
      const cellId = "celda" + String(x) + String(y - 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);

      if (button != null) {
        button.dispatchEvent(evt);
      } else {
        event.preventDefault(button);
      }
    }
    if (keyPress === "ArrowDown") {
      const cellId = "celda" + String(x) + String(y + 1) + "scrollable";
      this.cellSelected = cellId;
      var button = document.getElementById(cellId);
      if (button != null) {
        button.dispatchEvent(evt);
      } else {
        event.preventDefault(button);
      }
    }
  }

  // Initialize the create new batch modal
  getUnassignedDrivers() {
    // get the dates for next tuesday and next monday
    const tuesday = 2;
    const monday = 1;

    const today = new Date();
    const nextTuesday = new Date();
    const day = today.getDay() || 7;
    if (day !== tuesday) {
      nextTuesday.setHours(24 * (tuesday + 7 - day));
    }
    const nextMonday = new Date(nextTuesday.getTime());
    nextMonday.setHours(24 * (monday + 7 - tuesday));

    nextTuesday.setHours(0, 0, 0, 0);
    nextMonday.setHours(0, 0, 0, 0);

    this.newBatchModalData.startDate = nextTuesday;
    this.newBatchModalData.endDate = nextMonday;

    this._SettlementsService.listUnassignedDrivers().subscribe(
      (result) => {
        this.newBatchModalData.unassignedDrivers = result;
        // Default to all drivers being selected
        this.selectAllDriversNewBatchToggle = true;
        this.selectAllDriversInNewBatchModalChanged(true);
        this.cdr.markForCheck();
      },
      (resError) => {
        this.errorMsg = resError;
      }
    );
  }

  checkBatchSelected() {
    this.isBatchSelected = this.tableData.some((batch) => batch.isSelected == true);
  }

  toggleBatchesSelectAll() {
    this.batchesSelectAll = !this.batchesSelectAll;
    for (const row of this.tableData) {
      row.isSelected = this.batchesSelectAll;
    }
    this.checkBatchSelected();
  }

  createNewBatch() {
    if (this.newBatchModalData.startDate && this.newBatchModalData.endDate) {
      if (this.newBatchModalData.endDate >= this.newBatchModalData.startDate) {
        let driversCreate = [];
        for (let i = 0; i < this.newBatchModalData.unassignedDrivers.length; i++) {
          if (this.newBatchModalData.unassignedDrivers[i].batchInclude == true) {
            driversCreate.push(this.newBatchModalData.unassignedDrivers[i].id);
          }
        }
        if (driversCreate.length > 0) {
          this._SettlementsService
            .createBatchForDriverList(
              driversCreate,
              this.newBatchModalData.startDate,
              this.newBatchModalData.endDate
            )
            .subscribe(
              () => {
                document.getElementById("closeNewBatchModal").click();
                this.newBatchModalData = {
                  startDate: null,
                  endDate: null,
                  unassignedDrivers: [],
                };
                this.toastr.success("Batch Created Successfully", "Success!");
                this.getBatchesList();
                this.cdr.markForCheck();
              },
              (resError) => {
                this.errorMsg = resError;
              }
            );
        } else {
          alert("Select drivers for batch Creation");
        }
      } else {
        alert("Start date should be prior end date");
      }
    } else {
      alert("Please fill all the inputs");
    }
  }

  closeBackgroundEmailModal() {
    const closeModal = document.getElementsByClassName("p-ripple")[0] as HTMLElement;
    closeModal.click();
  }

  dateChanged(event, param, justDate, yearDigits) {
    this.maskService.maskDate(event, param, justDate, yearDigits);
  }

  // Make sure all express codes for this batch with >0 value have a charge type
  // Make sure all fuel expenses that are not non-highway have a driver
  // Make sure there are no errors in the import screen within this batch's date range
  async checkCanCloseBatch() {
    const globalBatchId = this.selectedBatch.globalBatchId;
    const errors = [];
    try {
      // 1. check for express codes with >0 value and no charge type
      const expressCodes = await this.tcheckService.getCompanyExpenses(globalBatchId).toPromise();

      const missingChargeTypes = expressCodes.some(
        (expense) => expense.chargeTypeId == null && expense.invoicetotal != 0
      );
      if (missingChargeTypes) {
        errors.push("There are some express codes for this batch that are missing a charge type");
      }

      // 2. check for fuel expenses that are not non-highway and have no driver
      const fuelExpenses = await this.tcheckService.getDriverExpenses(globalBatchId).toPromise();

      const missingDrivers = fuelExpenses.some(
        (expense) => (!expense.driverID || !expense.batchNo) && !expense.isNonHighwayTransaction
      );
      if (missingDrivers) {
        errors.push("There are some fuel expenses for this batch that are missing a driver");
      }

      // 3. check for errors in the import screen
      const records = await this.tcheckService.getScreenRecords().toPromise();
      const recordsWithErrors = await this.tcheckService.refreshRecords(records).toPromise();

      let { startDate, endDate } = this.selectedBatch;
      startDate = new Date(startDate);
      endDate = new Date(endDate);
      const errorsInRange = recordsWithErrors.filter(
        (record: any) =>
          record.errorMsg && new Date(record.date) >= startDate && new Date(record.date) <= endDate
      );
      if (errorsInRange.length > 0) {
        errors.push("Batch cannot be closed, there are errors in the import screen");
      }

      if (errors.length > 0) {
        for (const error of errors) {
          this.toastr.error(error, "Error!");
        }
        return false;
      } else {
        return true;
      }
    } catch {
      this.toastr.error("There was an error checking the batch", "Error!");
      return false;
    }
  }

  async checkCanCloseWarnings() {
    // get date in cst from date string "yyyy-mm-dd"
    const getDateInCentralTime = (dateString: string) => {
      const date = new Date(dateString);
      const utcDate = new Date(date.toUTCString());
      utcDate.setHours(utcDate.getHours() + 6);
      return utcDate;
    };

    const globalBatchId = this.selectedBatch.globalBatchId;
    // Show a warning if days in the settlement batch range do not have any fuel expense imported
    const fuelExpenses = await this.tcheckService.getDriverExpenses(globalBatchId).toPromise();
    const fuelExpenseDates = fuelExpenses.map((expense) => expense.date);
    const batchStartDate = getDateInCentralTime(this.selectedBatch.startDate);
    const batchEndDate = getDateInCentralTime(this.selectedBatch.endDate);

    const missingFuelExpenseDates = [];
    for (
      let date = new Date(batchStartDate);
      date <= batchEndDate;
      date.setDate(date.getDate() + 1)
    ) {
      // parse the date into yyyy-mm-dd format to match the fuel expense
      const formattedDate = this._DatePipe.transform(date, "yyyy-MM-dd");
      if (!fuelExpenseDates.some((expenseDate) => expenseDate == formattedDate)) {
        const dateCopy = new Date(date);
        missingFuelExpenseDates.push(dateCopy);
      }
    }

    if (missingFuelExpenseDates.length > 0) {
      // show a modal asking the user to confirm
      const formattedDates = missingFuelExpenseDates.map((date) =>
        this._DatePipe.transform(date, "MM/dd/yyyy")
      );
      const formattedDateList = formattedDates.join(", ");
      return window.confirm(
        `There are no fuel expenses imported for the following dates: ${formattedDateList}. Are you sure you want to close this batch?`
      );
    }

    return true;
  }

  closeBatchForAll() {
    this._ConfirmationService.confirm({
      message: "Are you sure you want to close this batch?",
      accept: async () => {
        if (this.batch?.status != "ACTIVE") {
          this.toastr.error("Batch is not active", "Error!");
          return;
        }
        const canClose = await this.checkCanCloseBatch();
        if (!canClose) return;

        const checkWarnings = await this.checkCanCloseWarnings();
        if (!checkWarnings) return;

        this._SettlementsService.closeBatchForAll(this.batch.batchNo).subscribe(
          () => {
            this.toastr.success("Batch Closed Successfully", "Success!");
            this.getBatchesList();
            this.cdr.markForCheck();
          },
          (resError) => {
            console.error(resError);
            this.toastr.error("There was an error closing the batch", "Error!");
          }
        );
      },
      reject: () => {},
    });
  }

  releaseBatchForAll() {
    this._ConfirmationService.confirm({
      message: "Are you sure you want to release this batch?",
      accept: async () => {
        if (this.batch?.status != "ACTIVE") {
          this.toastr.error("Batch is not active", "Error!");
          return;
        }

        this._SettlementsService.releaseBatchForAll(this.batch.batchNo).subscribe(
          () => {
            this.toastr.success("Batch Released Successfully", "Success!");
            this.getBatchesList();
            this.cdr.markForCheck();
          },
          (resError) => {
            console.error(resError);
            this.toastr.error("There was an error releasing the batch", "Error!");
          }
        );
      },
      reject: () => {},
    });
  }

  newBatchModalCancelled() {
    this.newBatchModalData = {
      startDate: null,
      endDate: null,
      unassignedDrivers: [],
    };
  }

  getDriversByBatch() {
    if (typeof this.batch.batchNo !== "undefined" && this.batch.batchNo != null) {
      this._SettlementsService.getDriversByBatch(this.batch.batchNo).subscribe(
        (resDriversByBatch) => {
          this.batchDrivers = resDriversByBatch;
          for (let i = 0; i < this.batchDrivers.length; i++) {
            this.batchDrivers[i].newDriverBatch = this.batchDrivers[i].belongsToBatch;
          }
          this.cdr.markForCheck();
        },
        (resError) => {
          this.errorMsg = resError;
        }
      );
    }
  }

  saveDriverBatch() {
    const newDrivers = this.batchDrivers.filter(
      (driver) => driver.newDriverBatch == true && driver.belongsToBatch == false
    );
    const removeDrivers = this.batchDrivers.filter(
      (driver) => driver.newDriverBatch == false && driver.belongsToBatch == true
    );
    if (
      (newDrivers.length && newDrivers.length > 0) ||
      (removeDrivers.length && removeDrivers.length > 0)
    ) {
      this._SettlementsService
        .changeDriversBatch(newDrivers, removeDrivers, this.batch.batchNo)
        .subscribe(
          () => {
            document.getElementById("closeBatchModal").click();
            this.batchInfo();
            this.cdr.markForCheck();
          },
          (resError) => {
            this.errorMsg = resError;
          }
        );
    }
  }

  saveBatchDataChange() {
    if (this.batch && this.batch.status != "CLOSED") {
      if (this.batchDataChanged.length === 0) return;
      this._SettlementsService.saveBatchDataChange(this.batchDataChanged).subscribe(
        () => {
          this.toastr.success("Changes saved Successfully", "Success!");
          this.batchInfo();
          this.cdr.markForCheck();
        },
        (resError) => {
          this.errorMsg = resError;
        }
      );
    }
  }

  confirm() {
    const gridHasChanged = this.batchDataChanged.find(
      (deduction) => deduction.value != deduction.originalValue
    );

    if (gridHasChanged) {
      return window.confirm(
        "You have unsaved Batch changes. Are you sure you want to leave this page?"
      );
    } else {
      return true;
    }
  }

  sendDriverSettlementEmails() {
    const drivers = this.tableData
      .filter((batch) => batch.isSelected)
      .map((batch) => ({ id: batch.id, batchId: batch.id }));

    this.driversToEmail = { drivers: drivers };

    this.displaySendBatchEmails = true;
  }

  batchEmailsSent() {
    this.refreshGrid();
    this.displayBackgroundBatchEmails = true;
  }

  refreshGrid() {
    this.batchInfo();
  }

  /** ------------------ NAVIGATION --------------------*/
  driverSettlements(driverId: string | number) {
    if (driverId && this.batch.batchNo) {
      this._DataSharingService.changeBatchNo(this.batch.batchNo);
      this._NgZone.run(() => this.router.navigate(["/settlements/loads/", driverId]));
    }
  }

  async generateExcel() {
    const XLSX = await import("xlsx");
    const book = XLSX.utils.book_new();
    const sheet = XLSX.utils.aoa_to_sheet([[]]);

    const cellTypeMap = {
      string: "s",
      number: "n",
    };

    const headers = [...this.frozenColumns, ...this.scrollableColumns];

    const headerCellObjects = headers.map((header) => ({
      t: cellTypeMap["string"],
      v: header.name.toUpperCase(),
    }));

    const valueCellObjects = this.tableData.map((record) => {
      return headers.map((header) => {
        let value = record[header.prop];
        const datatype = typeof value;

        if (!Object.keys(cellTypeMap).includes(datatype)) return null;
        if (datatype === "string") value = value.toUpperCase();
        if (datatype === "number" && !value) return null;

        return {
          t: cellTypeMap[datatype],
          v: value,
        };
      });
    });

    const totalCellOrigin = `A${this.tableData.length + 2}`;
    const totalCellObjects = headers.map((header) => {
      let value = Number(this.totals[header.prop]);
      if (isNaN(value) || value === 0) return null;

      return {
        t: cellTypeMap["number"],
        v: this.totals[header.prop],
      };
    });

    XLSX.utils.sheet_add_aoa(sheet, [headerCellObjects], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(sheet, valueCellObjects, { origin: "A2" });
    XLSX.utils.sheet_add_aoa(sheet, [totalCellObjects], { origin: totalCellOrigin });
    XLSX.utils.book_append_sheet(book, sheet, "Settlements");
    XLSX.writeFile(book, "Settlements.xlsx");
  }

  selectAllDriversInDriversBatchModalChanged(checked: boolean) {
    this.batchDrivers.forEach((driver) => {
      driver.newDriverBatch = checked;
    });
  }

  selectAllDriversInNewBatchModalChanged(shouldInclude: boolean) {
    for (let driver of this.newBatchModalData.unassignedDrivers) {
      driver.batchInclude = shouldInclude;
    }
  }

  driverToIncludeChanged() {
    this.selectAllDriversInDriversBatchModal = false;
  }

  driverToIncludeInNewBatchChanged() {
    this.selectAllDriversNewBatchToggle = false;
  }

  setSelectedCellFromClick(event: any) {
    this.cellSelected = event.currentTarget.id;
  }

  deleteDeductions() {
    this._ConfirmationService.confirm({
      message:
        "Are you sure you want to delete deductions?  <br/> <br/> This will remove all entered deductions/escrow/deficit. ",
      accept: async () => {
        this._SettlementsService.deleteDeductions(this.batch.batchNo).subscribe(() => {
          this.toastr.success("Removed imported deductions", "Success!");
          this.batch.importedDeductions = false;
          this.batchInfo();
          this.cdr.markForCheck();
        });
      },
      reject: () => {},
    });
  }

  deleteFuel() {
    this._ConfirmationService.confirm({
      message:
        "Are you sure you want to delete fuel?  <br/> <br/> This will remove all Tchek records for this batch.",
      accept: async () => {
        this._SettlementsService.deleteFuel(this.batch.batchNo).subscribe(() => {
          this.toastr.success("Removed imported fuel", "Success!");
          this.batch.importedFuel = false;
          this.batchInfo();
          this.cdr.markForCheck();
        });
      },
      reject: () => {},
    });
  }
}
