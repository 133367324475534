import { LocationViewModel } from "../locations/LocationViewModel.model";
import { BaseRate } from "./BaseRate.model";

export type ClientDriverRateViewModel = {
  weightOrDistance: "WEIGHT" | "DISTANCE";
  equipmentOrProduct: "EQUIPMENT" | "PRODUCT";
  rateByUnits: "US POUNDS" | "US TONS" | "US GALLONS" | "STANDARD CUBIC FEET";
  teamCharge: number;
  isOneWay: boolean;
  loadSetFreeTimePerStop: boolean;
  unloadSetFreeTimePerStop: boolean;
  delaySetFreeTimePerStop: boolean;
  decimals: number;

  origins: any[];
  destinations: any[];

  rateItems: RateItemViewModel[];
  excessRateItems: ExcessRateItemViewModel[];
  loadingCharges: LoadingChargeViewModel[];
  products: any[];
  equipmentCodes: any[];

  teamChargeTypeId?: string;
  singleChargeTypeId?: string;
  excessMilesChargeTypeId?: string;
  loadChargeTypeId?: string;
  unloadChargeTypeId?: string;
  delayChargeTypeId?: string;
  stopChargeTypeId?: string;

  laneOverrides?: LaneOverrideViewModel[];

  teamChargeType?: any;
  singleChargeType?: any;
  excessMilesChargeType?: any;
  loadChargeType?: any;
  unloadChargeType?: any;
  delayChargeType?: any;
  stopChargeType?: any;
} & BaseRate;

export type LoadingChargeViewModel = {
  id?: string;
  loadingChargeType: string;

  milesFloor: number;
  milesRoof: number;
  freeTime: number;
  pricePerHour: number;
  timeInterval: number;
  stop1: number;
  stop2: number;
  stop3: number;
  stop4: number;
  stop1FreeTime: number;
  stop2FreeTime: number;
  stop3FreeTime: number;
  stop4FreeTime: number;

  useFreeTimePerStop?: boolean;

  clientRateId?: string;
  driverRateId?: string;
};

export type ExcessRateItemViewModel = {
  id?: string;
  type: string;
  rate: number;
  kind: string;
  clientRateId?: string;
  driverRateId?: string;
};

export type RateItemViewModel = {
  id?: string;
  rate: number;
  kind: string;
  floor: number;
  roof: number;
  clientRateId?: string;
  driverRateId?: string;
};

class ProductValueViewModel {
  productId: string;
  equipmentCodeId: string;
  value: number;
  product?: any;
  equipmentCode?: any;
}
export class LaneOverrideViewModel {
  loadLocationId: string;
  unloadLocationId: string;
  routeMiles: number;
  forceMiles: boolean;
  suppressFuelSurcharge: boolean;
  startDate?: Date;
  endDate?: Date;
  productValues: ProductValueViewModel[];
  loadLocation?: LocationViewModel;
  unloadLocation?: LocationViewModel;
  loadingMiles?: boolean;
}
