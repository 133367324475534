import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MaskService } from "@app/shared/services/mask/mask.service";

@Component({
  selector: "app-efs-import-modal",
  templateUrl: "./efs-import-modal.component.html",
  styleUrl: "./efs-import-modal.component.css",
})
export class EfsImportModalComponent implements OnInit {
  @Input() visible: boolean = false;
  @Output() visibleChange = new EventEmitter<boolean>();

  date: Date = new Date();
  dateInputMask: any;

  constructor(private maskService: MaskService) {}

  ngOnInit() {
    this.dateInputMask = this.maskService.getInputDateOnlyMask();
  }

  closeModal() {
    this.date = new Date();
    this.visible = false;
    this.visibleChange.emit(this.visible);
  }

  import() {
    this.closeModal();
  }
}
