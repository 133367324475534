import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { EfsChargeTypeMapping } from "@app/shared/models/fuel/EfsChargeTypeMapping.model";
import { catchError, map, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EfsService {
  constructor(private _http: HttpClient) {}

  getImportedTransactions(
    filter = {},
    pageSize = 20,
    pageOffset = 0,
    sortField: string,
    sortOrder: number
  ) {
    return this._http
      .post("api/fuel/getAllTransactions", {
        filter,
        pageSize,
        pageOffset,
        sortField,
        sortOrder,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => throwError(error.message || "Server error"))
      );
  }

  getImportedMoneyCodes(
    filter = {},
    pageSize = 20,
    pageOffset = 0,
    sortField: string,
    sortOrder: number
  ) {
    return this._http
      .post("api/fuel/getAllMoneyCodes", {
        filter,
        pageSize,
        pageOffset,
        sortField,
        sortOrder,
      })
      .pipe(
        map((res) => {
          return res;
        }),
        catchError((error: any) => throwError(error.message || "Server error"))
      );
  }

  getLastSyncDate() {
    return this._http.get("/api/fuel/lastSyncDate").pipe(map((res) => res));
  }

  sync() {
    return this._http.post("/api/fuel/downloadTransactions", {}).pipe(map((res) => res));
  }

  importFromEfs(endDate: Date) {
    return this._http.post("/api/fuel/importFromEfs", endDate).pipe(map((res) => res));
  }

  getChargeTypeMappings() {
    return this._http
      .get("/api/fuel/chargeTypeMappings")
      .pipe(map((res: EfsChargeTypeMapping[]) => res));
  }

  addChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http
      .post("/api/fuel/addChargeTypeMapping", mapping)
      .pipe(map((res: EfsChargeTypeMapping) => res));
  }

  editChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http
      .patch(`/api/fuel/editChargeTypeMapping/`, mapping)
      .pipe(map((res: EfsChargeTypeMapping) => res));
  }

  deleteChargeTypeMapping(id: string) {
    return this._http.delete(`/api/fuel/deleteChargeTypeMapping/${id}`).pipe(map((res) => res));
  }

  restoreChargeTypeMapping(mapping: EfsChargeTypeMapping) {
    return this._http.patch(`/api/fuel/restoreChargeTypeMapping`, mapping).pipe(map((res) => res));
  }
}
